<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout justify-end>
            <v-btn
                color="primary"
                text
                @click="add"
            >
                Add Item
            </v-btn>
            <v-btn
                color="grey"
                text
                @click="cancel"
            >
                Cancel
            </v-btn>
        </v-layout>

        <v-layout
            justify-center
            wrap
        >
            <confirm-dialog
                :show="confirmDeleteDialog.show"
                @confirm="removeServiceKey"
                @cancel="hideConfirmDeleteDialog"
            >
                <template #header>
                    Confirm action
                </template>
                <template #body>
                    Are you sure you want to delete this entry?
                </template>
            </confirm-dialog>

            <v-flex xs12>
                <v-alert
                    :value="validationErrors.length"
                    type="error"
                    class="modules__alert"
                    dismissible
                >
                    The configuration has the following errors:

                    <ul>
                        <li
                            v-for="(err, idx) in validationErrors"
                            :key="idx"
                        >
                            {{ err }}
                        </li>
                    </ul>
                </v-alert>
                <material-card
                    :title="serviceId"
                    color="tertiary"
                >
                    <service-config-table
                        :loading="loading"
                        :service="bundleConfigData.values"
                        :creating="creating"
                        :types="types"
                        @create="createServiceKey"
                        @cancel-create="cancelCreate"
                        @update="updateService"
                        @delete="showConfirmDeleteDialog"
                    />
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import _ from 'lodash';
import AuthConfigRequests from '../../utils/requests/authConfig';
import ServiceConfigTable from './ServiceConfigTable';
import NewServiceDialog from './NewServiceDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import ValidateModule from '@/views/modules/utils/ValidateModule';
import Dependencies from '@/dependencyContainer/dependenciesList';

export default {
    name: 'ServiceConfig',
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Connectors Configuration'
        };
    },
    components: {
        NewServiceDialog,
        ServiceConfigTable,
        ConfirmDialog
    },
    props: {
        serviceId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            moduleId: '',
            validationResult: '',
            defaults: {},
            types: [],
            missedAttrs: [],
            bundleConfigData: {
                schema: {},
                values: {},
                errors: []
            },
            creating: false,
            confirmDeleteDialog: {
                show: false,
                key: null
            }
        };
    },

    computed: {
        validationErrors() {
            return this.bundleConfigData.errors.map(err => err.message);
        }
    },

    created() {
        this.loadBundleConfigData();
        this.moduleId = this.serviceId.replace(/\./g, ':');
    },

    methods: {
        async loadBundleConfigData() {
            this.loading = true;
            try {
                const authReq = await this.$dc.get(Dependencies.AuthConfigRequests);
                const validationResult = await ValidateModule.validateConfig(authReq, this.serviceId);

                this.bundleConfigData = {
                    schema: validationResult.configurationSchema,
                    values: validationResult.configurationValues,
                    errors: validationResult.errors || []
                };
            } finally {
                this.loading = false;
            }
        },

        add() {
            this.creating = true;
        },

        cancel() {
            this.$router.push({ path: '/dashboard/service-config' });
        },

        cancelCreate() {
            this.creating = false;
        },

        async updateValidation() {
            const authReq = await this.$dc.get(Dependencies.AuthConfigRequests);
            const result = await ValidateModule.validateConfig(authReq, this.serviceId);
            this.assignErrorValue(result.errors || []);
        },

        assignConfigValues(values) {
            this.$set(this.bundleConfigData, 'values', values);
        },

        assignErrorValue(value) {
            this.$set(this.bundleConfigData, 'errors', value);
        },

        async createServiceKey({ key, value }) {
            this.creating = false;
            const values = await AuthConfigRequests.updateAuthConfig(this.serviceId, {
                ...this.bundleConfigData.values,
                [key]: value
            });
            this.assignConfigValues(values);
            await this.updateValidation();
        },

        async updateService({ oldKey, key, value }) {
            const payload = _.omit(this.bundleConfigData.values, [oldKey, 'serviceId']);
            payload[key] = value;
            const values = await AuthConfigRequests.updateAuthConfig(this.serviceId, payload);
            this.assignConfigValues(values);
            await this.updateValidation();
        },

        showConfirmDeleteDialog(key) {
            this.confirmDeleteDialog.show = true;
            this.confirmDeleteDialog.key = key;
        },

        hideConfirmDeleteDialog() {
            this.confirmDeleteDialog.show = false;
            this.confirmDeleteDialog.key = null;
        },

        async removeServiceKey() {
            const payload = _.omit(this.bundleConfigData.values, [this.confirmDeleteDialog.key, 'serviceId']);
            this.hideConfirmDeleteDialog();
            const values = await AuthConfigRequests.updateAuthConfig(this.serviceId, payload);
            this.assignConfigValues(values);
            await this.updateValidation();
        }
    }
};
</script>

<style scoped>

</style>
